exports.components = {
  "component---src-components-new-property-page-jsx": () => import("./../../../src/components/NewPropertyPage.jsx" /* webpackChunkName: "component---src-components-new-property-page-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acorn-hive-index-js": () => import("./../../../src/pages/acorn-hive/index.js" /* webpackChunkName: "component---src-pages-acorn-hive-index-js" */),
  "component---src-pages-amrutha-platinum-towers-index-js": () => import("./../../../src/pages/amrutha-platinum-towers/index.js" /* webpackChunkName: "component---src-pages-amrutha-platinum-towers-index-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-index-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}/index.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-index-jsx" */),
  "component---src-pages-grc-sapphire-springs-codename-sunday-index-jsx": () => import("./../../../src/pages/grc-sapphire-springs-codename-sunday/index.jsx" /* webpackChunkName: "component---src-pages-grc-sapphire-springs-codename-sunday-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-navami-landmaark-index-js": () => import("./../../../src/pages/navami-landmaark/index.js" /* webpackChunkName: "component---src-pages-navami-landmaark-index-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-the-belvedere-by-ukn-index-js": () => import("./../../../src/pages/the-belvedere-by-ukn/index.js" /* webpackChunkName: "component---src-pages-the-belvedere-by-ukn-index-js" */),
  "component---src-pages-vanshika-natures-harmony-index-jsx": () => import("./../../../src/pages/vanshika-natures-harmony/index.jsx" /* webpackChunkName: "component---src-pages-vanshika-natures-harmony-index-jsx" */)
}

